/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import WhatsAppAPI from '../../api/WhatsAppAPI';
import { toast } from 'react-toastify';//npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';
// import moment from 'moment-timezone';

const TemplateModal = ({ show, onHide, contactData, refreshData, filterData }) => {
    const [allTemplateData, setAllTemplateData] = useState([]);
    const [selectedTemplateName, setSelectedTemplateName] = useState();
    const [isSpinner, setIsSpinner] = useState(false)

    console.log('filterData', filterData)

    useEffect(() => {
        fetchAllTemplate();
    }, []);

    const fetchAllTemplate = async () => {
        const result = await WhatsAppAPI.getApprovedTemplates();
        console.log('result', result)
        if (result.error) {
            setAllTemplateData([])
            setIsSpinner(true);
        } else {
            const transformedData = result?.data.map(row => {
                const header = row.components.find(component => component.type === 'HEADER') || {};
                const body = row.components.find(component => component.type === 'BODY') || {};
                const footer = row.components.find(component => component.type === 'FOOTER') || {};
                const buttons = row.components.find(component => component.type === 'BUTTONS')?.buttons || [];
                return {
                    id: row.id,
                    name: row.name,
                    templatename: row.name.replace(/_/g, ' '),
                    language: row.language,
                    status: row.status,
                    category: row.category,
                    header: header.format || '',

                    // Handling header types
                    header_text: header.format === 'TEXT' ? header.text : '',
                    header_image_url: header.format === 'IMAGE' ? (header.example?.header_handle?.[0] || '') : '',
                    header_document_url: header.format === 'DOCUMENT' ? (header.example?.header_handle?.[0] || '') : '',
                    header_video_url: header.format === 'VIDEO' ? (header.example?.header_handle?.[0] || '') : '',

                    // Body and example text
                    message_body: body.text || '',
                    example_body_text: body.example?.body_text || [], // Extracting example body text
                    // Footer and security recommendations
                    add_security_recommendation: body.add_security_recommendation || false,
                    code_expiration_minutes: footer.code_expiration_minutes || null,
                    footer: footer.text || '',

                    // Extracting button details
                    // buttons: buttons.map(button => {
                    //     if (button.type === 'OTP') {
                    //         return {
                    //             type: button.type,
                    //             otp_type: button.otp_type,
                    //             supported_apps: button.supported_apps?.map(app => ({
                    //                 package_name: app.package_name,
                    //                 signature_hash: app.signature_hash,
                    //             })) || [],
                    //         };
                    //     } else {
                    //         return {
                    //             type: button.type,
                    //             text: button.text,
                    //             ...(button.type === 'PHONE_NUMBER' && { phone_number: button.phone_number }),
                    //             ...(button.type === 'URL' && { url: button.url }),
                    //         };
                    //     }
                    // }).filter(button => button.text && button.type),
                    // Extracting button details
                    buttons: buttons.map(button => {
                        return {
                            type: button.type,
                            text: button.text,
                            ...(button.type === 'PHONE_NUMBER' && { phone_number: button.phone_number }),
                            ...(button.type === 'URL' && { url: button.url }),
                        };
                    }).filter(button => button.text && button.type),  // Only include valid buttons

                };
            });

            console.log('transformedData', transformedData);
            setAllTemplateData(transformedData);
            setIsSpinner(true);
        }
    }

    const handleChange = async (event) => {
        const selectedName = event.target.value;

        const template = allTemplateData.find(t => t.name === selectedName);
        console.log('###template', template)

        if (template) {
            const { id, name, language, category, message_body, header_text, footer, example_body_text, buttons } = template;
            const exampleValue = example_body_text.length > 0 && example_body_text[0].length > 0 ? example_body_text[0][0] : '';
            const finalMessageBody = message_body.replace("*{{1}}*", exampleValue).trim();
            const combinedMessage = `${header_text}\n${finalMessageBody}\n${footer}`.trim();
            const url = template.header_image_url || template.header_document_url || template.header_video_url || '';
            const website = buttons.find(button => button.text.toLowerCase() === 'website')?.url || '';
            const call = buttons.find(button => button.text.toLowerCase() === 'call')?.phone_number || '';

            const formattedTemplate = { id, name, language, category, url, exampleValue, website, call, body_message: combinedMessage };
            console.log('formattedTemplate', formattedTemplate);
            setSelectedTemplateName(formattedTemplate)
        } else {
            setSelectedTemplateName();
        }
    }


    // Function to fetch the file and convert it to a Blob
    async function fetchFile(url) {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        return await response.blob(); // Return the Blob
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('selectedTemplateName', selectedTemplateName)
        let documentId = null;
        setIsSpinner(false);

        try {
            const msgResult = await WhatsAppAPI.createMessageTemplateData(selectedTemplateName);
            console.log('#msgResult', msgResult)

            if (msgResult?.errors) {
                toast.error(msgResult?.errors);
                setIsSpinner(true);
                return;
            }

            if (selectedTemplateName.url) {
                const fileUrl = selectedTemplateName.url;
                const fileBlob = await fetchFile(fileUrl); // Fetch the file
                // const fileName = 'image.jpg';
                const fileName = fileUrl.split('/').pop().split('?')[0] || 'image.jpg';
                console.log('fileName', fileName)

                const file = new File([fileBlob], fileName, { type: fileBlob.type });
                const formData = new FormData();
                formData.append("messaging_product", "whatsapp");
                formData.append("file", file); // Use the File object here
                formData.append("description", 'Header Image Template'); // Include file description

                // Upload the document using WhatsApp API
                const documentResult = await WhatsAppAPI.uploadDocumentWithApi(formData);
                documentId = documentResult.id
                console.log('###result file id: ', documentId);
            }

            const reqBody = {
                messaging_product: 'whatsapp',
                to: contactData.whatsapp_number,
                type: 'template',
                category: selectedTemplateName.category,
                template: {
                    name: selectedTemplateName.name,
                    language: {
                        code: selectedTemplateName.language,
                    },
                    components: [
                        {
                            type: "header",
                            parameters: []
                        },
                        {
                            type: "BODY",
                            parameters: []
                        },
                    ]
                },
            };

            if (documentId) {
                reqBody.template.components[0].parameters.push({
                    type: "image",
                    image: {
                        id: documentId
                    }
                });
            }


            if (selectedTemplateName.exampleValue) {
                reqBody.template.components[1].parameters.push({
                    type: "text",
                    text: selectedTemplateName.exampleValue
                });
            }
            if (selectedTemplateName.exampleValue) {
                reqBody.template.components.push({
                    type: "BUTTON",
                    sub_type: "url",
                    index: 0,
                    parameters: [
                        {
                            type: "text",
                            text: selectedTemplateName.exampleValue //"J$FpnYnP" // Button text
                        }
                    ]
                });
            }

            console.log('reqBody', reqBody)

            const result = await WhatsAppAPI.sendWhatsAppTemplateMessage(reqBody);
            console.log('result', result)

            if (result.error) {
                const msgError = result.error.error_data;
                toast.error(`Error: ${msgError.details}`);
                // toast.error(`Error: Message could not be sent to this number ${number} due to a bad request.`);
                console.log('##ERROR', result.error)
                setIsSpinner(true);
            } else {
                const newMessage = {
                    parent_id: contactData.id || null,
                    name: contactData?.contactname || '',
                    message_template_id: msgResult.id || null,
                    whatsapp_number: contactData.whatsapp_number,
                    message: '',
                    status: 'Outgoing',
                    recordtypename: filterData?.recordType || '',
                    file_id: null,
                    website: selectedTemplateName.website,
                    call: selectedTemplateName.call,
                    copy_code: selectedTemplateName.exampleValue,
                    is_read: true
                }

                const response = await WhatsAppAPI.insertMsgHistoryRecords(newMessage);
                if (response.error) {
                    console.log('response.error', response.error)
                }
            }
        } catch (error) {
            toast.error("Failed to send message.");
            setIsSpinner(true);
        } finally {
            setIsSpinner(true);
            refreshData();
        }
    }

    const isFormValid = Boolean(selectedTemplateName);

    return (
        <>
            <Modal show={show} animation={false} size='lg' centered>
                <Modal.Header closeButton onClick={onHide}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Send WhatsApp Message
                    </Modal.Title>
                </Modal.Header>
                {isSpinner ?
                    <>
                        <Modal.Body>
                            <Form noValidate >
                                <Row className='p-2 mb-3'>
                                    <Col lg={12} sm={12} xs={12}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                Template Name
                                            </Form.Label>
                                            <Form.Select
                                                required
                                                style={{ height: '36px' }}
                                                aria-label="select name"
                                                name="templateName"
                                                onChange={handleChange}
                                                placeholder='Select Template Name'
                                            // value={templateName}
                                            >
                                                <option value="">Select Template Name</option>
                                                {allTemplateData?.map((template) => (
                                                    <option key={template.id} value={template.name}>
                                                        {template.templatename}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>

                        <Modal.Footer>
                            <button className='btn btn-light' onClick={onHide}>Close</button>
                            <button className='btn btn-outline-secondary' onClick={handleSubmit} disabled={!isFormValid}>Send Message</button>
                        </Modal.Footer>
                    </>
                    :
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                }
            </Modal>
        </>
    )
}

export default TemplateModal
