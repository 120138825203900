/**
 * @author      Abdul Pathan
 * @date        Aug, 2024
 * @copyright   www.ibirdsservices.com
 */

import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import WhatsAppAPI from '../../api/WhatsAppAPI';
import { toast } from 'react-toastify';//npm i react-toastify --force
import 'react-toastify/dist/ReactToastify.css';
// import moment from 'moment-timezone';

const MessageTemplateModal = ({ show, onHide, contactData, refreshData, filterData }) => {
    const [allTemplateData, setAllTemplateData] = useState([]);
    const [selectedTemplateName, setSelectedTemplateName] = useState('');
    const [textMessage, setTextMessage] = useState('');
    const [isSpinner, setIsSpinner] = useState(false);
    const [body, setBody] = useState();
    const [groupId, setGroupId] = useState('');

    useEffect(() => {
        fetchAllTemplate();
        fetchAllGroupRecord();
    }, []);


    const fetchAllGroupRecord = async () => {
        if (contactData.length > 0) {
            if (!contactData[0].whatsapp_number) {
                const recordId = contactData[0].id;
                setGroupId(recordId);
                const result = await WhatsAppAPI.fetchGroupsById(recordId);
                if (result.success && result.records?.members.length > 0) {
                    setBody(result.records?.members);
                } else {
                    setBody([]);
                }
            } else {
                setBody(contactData);
            }
        }
    }

    const fetchAllTemplate = async () => {
        const result = await WhatsAppAPI.getApprovedTemplates();
        if (result.error) {
            setAllTemplateData([])
            setIsSpinner(true);
        } else {
            const transformedData = result?.data.map(row => {
                const header = row.components.find(component => component.type === 'HEADER') || {};
                const body = row.components.find(component => component.type === 'BODY') || {};
                const footer = row.components.find(component => component.type === 'FOOTER') || {};
                const buttons = row.components.find(component => component.type === 'BUTTONS')?.buttons || [];
                return {
                    id: row.id,
                    name: row.name,
                    templatename: row.name.replace(/_/g, ' '),
                    language: row.language,
                    status: row.status,
                    category: row.category,
                    header: header.format || '',

                    header_text: header.format === 'TEXT' ? header.text : '',
                    header_image_url: header.format === 'IMAGE' ? (header.example?.header_handle?.[0] || '') : '',
                    header_document_url: header.format === 'DOCUMENT' ? (header.example?.header_handle?.[0] || '') : '',
                    header_video_url: header.format === 'VIDEO' ? (header.example?.header_handle?.[0] || '') : '',

                    message_body: body.text || '',
                    example_body_text: body.example?.body_text || [],
                    footer: footer.text || '',

                    add_security_recommendation: body.add_security_recommendation || false,
                    code_expiration_minutes: footer.code_expiration_minutes || null,

                    buttons: buttons.map(button => {
                        if (button.type === 'OTP') {
                            return {
                                type: button.type,
                                otp_type: button.otp_type,
                                supported_apps: button.supported_apps?.map(app => ({
                                    package_name: app.package_name,
                                    signature_hash: app.signature_hash,
                                })) || [],
                            };
                        } else {
                            return {
                                type: button.type,
                                text: button.text,
                                ...(button.type === 'PHONE_NUMBER' && { phone_number: button.phone_number }),
                                ...(button.type === 'URL' && { url: button.url }),
                            };
                        }
                    }).filter(button => button.text && button.type),
                };
            });

            setAllTemplateData(transformedData);
            setIsSpinner(true);
        }
    }

    const handleChange = async (event) => {
        const selectedName = event.target.value;
        const template = allTemplateData.find(t => t.name === selectedName);
        console.log('###template', template)

        if (template) {
            const { id, name, language, category, message_body, header_text, footer, example_body_text, buttons } = template;
            const exampleValue = example_body_text.length > 0 && example_body_text[0].length > 0 ? example_body_text[0][0] : '';
            const finalMessageBody = message_body.replace("*{{1}}*", exampleValue).trim();
            const combinedMessage = `${header_text}\n${finalMessageBody}\n${footer}`.trim();
            const url = template.header_image_url || template.header_document_url || template.header_video_url || '';
            const website = buttons.find(button => button.text.toLowerCase() === 'website')?.url || '';
            const call = buttons.find(button => button.text.toLowerCase() === 'call')?.phone_number || '';

            const formattedTemplate = { id, name, language, category, url, exampleValue, call, website, body_message: combinedMessage };

            console.log('formattedTemplate', formattedTemplate);

            setSelectedTemplateName(formattedTemplate)
        } else {
            setSelectedTemplateName();
        }
    }

    async function fetchFile(url) {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        return await response.blob(); // Return the Blob
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSpinner(true);
        let successCount = 0;
        let documentId = null;
        let newMessage = null;
        try {

            if (selectedTemplateName.url) {
                const fileUrl = selectedTemplateName.url;
                const fileBlob = await fetchFile(fileUrl); // Fetch the file
                // const fileName = 'image.jpg';
                const fileName = fileUrl.split('/').pop().split('?')[0] || 'image.jpg';
                console.log('fileName', fileName)

                const file = new File([fileBlob], fileName, { type: fileBlob.type });
                const formData = new FormData();
                formData.append("messaging_product", "whatsapp");
                formData.append("file", file); // Use the File object here
                formData.append("description", 'Header Image Template'); // Include file description

                // Upload the document using WhatsApp API
                const documentResult = await WhatsAppAPI.uploadDocumentWithApi(formData);
                documentId = documentResult.id
                console.log('###result file id: ', documentId);
            }

            for (const contact of body) {
                const contactId = contact.id || groupId;
                const contactName = contact.contactname || `${contact.member_firstname} ${contact.member_lastname}`;

                const textMsgBody = textMessage || "Default text message";
                const isTextMessage = Boolean(textMessage);
                const singleText = {
                    messaging_product: "whatsapp",
                    recipient_type: "individual",
                    to: contact.whatsapp_number,
                    type: isTextMessage ? "text" : "template",
                    text: isTextMessage ? {
                        preview_url: false,
                        body: textMsgBody
                    } : undefined,
                    template: isTextMessage ? undefined : {
                        name: selectedTemplateName.name,
                        language: {
                            code: selectedTemplateName.language,
                        },
                        components: [
                            {
                                type: "header",
                                parameters: []
                            },
                            {
                                type: "BODY",
                                parameters: []
                            },
                        ]
                    }
                };

                if (!isTextMessage && documentId) {
                    singleText.template.components[0].parameters.push({
                        type: "image",
                        image: {
                            id: documentId
                        }
                    });
                }

                if (selectedTemplateName.exampleValue) {
                    singleText.template.components[1].parameters.push({
                        type: "text",
                        text: selectedTemplateName.exampleValue
                    });
                }
                if (selectedTemplateName.exampleValue) {
                    singleText.template.components.push({
                        type: "BUTTON",
                        sub_type: "url",
                        index: 0,
                        parameters: [
                            {
                                type: "text",
                                text: selectedTemplateName.exampleValue //"J$FpnYnP" // Button text
                            }
                        ]
                    });
                }

                console.log('###singleText', singleText);

                const result = isTextMessage
                    ? await WhatsAppAPI.sendSingleWhatsAppTextMessage(singleText)
                    : await WhatsAppAPI.sendWhatsAppTemplateMessage(singleText);

                console.log('##result', result);
                if (result.error) {
                    const msgError = result.error.error_data;
                    toast.error(`Error sending to ${contactName}: ${msgError.details || result.error}`);
                } else {
                    successCount++;

                    newMessage = {
                        parent_id: contactId || null,
                        name: contactName || '',
                        message_template_id: isTextMessage ? null : (await WhatsAppAPI.createMessageTemplateData(selectedTemplateName)).id || null,
                        whatsapp_number: contact.whatsapp_number,
                        message: isTextMessage ? textMsgBody : '',
                        status: 'Outgoing',
                        recordtypename: filterData.recordType || '',
                        file_id: null,
                        website: selectedTemplateName.website,
                        call: selectedTemplateName.call,
                        copy_code: selectedTemplateName.exampleValue,
                        is_read: true
                    };
                    if (filterData.recordType !== 'groups') {
                        const response = await WhatsAppAPI.insertMsgHistoryRecords(newMessage);
                    }
                }
            }

            if (filterData.recordType === 'groups') {
                const response = await WhatsAppAPI.insertMsgHistoryRecords(newMessage);
            }

            if (successCount > 0) {
                refreshData()
                toast.success(`${successCount} messages sent successfully!`);

            }

        } catch (error) {
            console.error("Error in sending messages:", error);
            toast.error("Failed to send messages.");
        } finally {
            setIsSpinner(false);
            refreshData();
        }
    }

    const isFormValid = Boolean(selectedTemplateName || textMessage);

    return (
        <>
            <Modal show={show} animation={false} size='lg' centered>
                <Modal.Header closeButton onClick={onHide}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Send WhatsApp Message
                    </Modal.Title>
                </Modal.Header>

                {isSpinner ?
                    <>
                        {body.length > 0 ?
                            <>
                                <Modal.Body>
                                    <Form noValidate >
                                        <Row className='p-2 mb-3'>
                                            <Col lg={12} sm={12} xs={12}>
                                                <Form.Group className='mb-4'>
                                                    <Form.Label className="form-view-label" htmlFor="formBasicFirstName">
                                                        Template Name
                                                    </Form.Label>
                                                    <Form.Select
                                                        style={{ height: '36px' }}
                                                        aria-label="select name"
                                                        name="templateName"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            if (textMessage) {
                                                                setTextMessage('');
                                                            }
                                                        }}
                                                        placeholder='Select Template Name'
                                                    // value={templateName}
                                                    >
                                                        <option value="">Select Template Name</option>
                                                        {allTemplateData?.map((template) => (
                                                            <option key={template.id} value={template.name}>
                                                                {template.templatename}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>

                                            </Col>

                                            {!selectedTemplateName &&

                                                <Col lg={12} sm={12} xs={12} className='mt-2'>
                                                    <Form.Group controlId="formTextMessage">
                                                        <Form.Label>Text Message</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            value={textMessage}
                                                            onChange={(e) => {
                                                                setTextMessage(e.target.value);
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            }
                                        </Row>
                                    </Form>
                                </Modal.Body>

                                <Modal.Footer>
                                    <button className='btn btn-light' onClick={onHide}>Close</button>
                                    <button className='btn btn-outline-secondary' onClick={handleSubmit} disabled={!isFormValid} >Send Message</button>
                                </Modal.Footer>
                            </>
                            :
                            <Modal.Body>
                                <Row>
                                    <Col lg={12} xs={12} sm={12}>
                                        <p>There is no members in group</p>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        }
                    </>
                    :
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                }
            </Modal>
        </>
    )
}

export default MessageTemplateModal