import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import PieChart from './charts/PieChart';
import DoughnutChart from './charts/DoughnutChart';

const Home = () => {

  return (
    <>
      <Container className='mt-5'>
        <Row className='mx-5 text-center g-0'>
          <Col lg={12} xs={12} sm={12}>
            <div className=' text-center p-2' style={{ height: '40px', backgroundColor: '#ffffff', borderRadius: '5px' }}>
              <span className='fw-semibold p-1' style={{ color: '#605C68', fontSize: 'large' }}>
                Dashboard
              </span>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className='mt-2'>
        <Row className='mx-5 text-center g-0'>
          <Col lg={12} xs={12} sm={12}>
            <Row>
              <Col lg={6} xs={12} sm={12}>
                <Card className='h-100 mb-3' style={{ border: "none" }}>
                  <Card.Title className="text-center mt-2">Campaign</Card.Title>
                  <div style={{ height: "300px" }}>
                    <PieChart />
                  </div>
                </Card>
              </Col>

              <Col lg={6} xs={12} sm={12}>
                <Card className='h-100 mb-3' style={{ border: "none" }}>
                  <Card.Title className="text-center mt-2">WhatsApp Templates</Card.Title>
                  <div style={{ height: "300px" }}>
                    <DoughnutChart />
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>


      {/* <Container className='mt-3'>
        <Row>
          <Col lg={6} sm={12} xs={12}>
            <Row className="ms-1">
              <Card className="p-3 shadow-sm d-flex justify-content-between align-items-center">
                <Card.Title className="text-center">WhatsApp Templates</Card.Title>
                <Col lg={12} sm={12} xs={12}  >
                  <div style={{ height: "400px" }}>
                    <DoughnutChart />
                  </div>
                </Col>
              </Card>
            </Row>
          </Col>

          <Col lg={6} sm={12} xs={12}>
            <Row className="ms-1">
              <Card className="p-3 shadow-sm d-flex justify-content-between align-items-center">
                <Card.Title className="text-center">Campaign</Card.Title>
                <Col lg={12} sm={12} xs={12} >
                  <div style={{ height: "400px" }}>
                    <PieChart />
                  </div>
                </Col>
              </Card>
            </Row>
          </Col>

        </Row>
      </Container> */}

    </>
  )
}

export default Home
