/** @format */
import Sidebar from "./Sidebar"
import Header from "./Header";
import { Outlet, Link } from "react-router-dom";
// import Footer from "../Footer";

const Main = ({ socket }) => {
  return (
    <div className="wrapper">
      <Sidebar />
      <div id="content">
        <Header socket={socket} />
        <Outlet />
        {/* <Footer /> */}
      </div>
    </div>

  );
};

export default Main;