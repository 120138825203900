import * as constants from '../constants/CONSTANT';
import authApi from "./authApi";

const WhatsAppAPI = {
  //************************ Leads ***********************************/ 

  //................... Create Lead ................................
  async createBackup(lead) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/backup",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(lead),

      });
    return await response.json();
  },



  //...................  Fetch All Lead ................................
  async fetchBackups() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/backup", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },





  //...................  Fetch All Business ................................
  async fetchBusiness() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/businesses", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Business By Id .............................

  async fetchBusinessById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/businesses/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //...................  Fetch All Lead ................................

  async fetchUsers() {
    //console.log('if call')
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    //console.log('response',response)


    const result = await response.json();
    //console.log('result',result)
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Lead By Id .............................
  async fetchUserById(id) {
    console.log("Beofre fetchUserById", id);
    id = id.trim();
    console.log("After fetchUserById", id);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/users/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    console.log('response ==> ', result)
    return result;
  },

  //fetchMedicalTestItemByOwnerId
  async findLeadByOwnerId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/ld/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },


  //.............. Update Lead .............................
  async updateBusiness(business) {
    console.log('business:', business);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/businesses/" + business.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(business),
      }
    );
    return await response.json();
  },


  // .......... Delete Lead .............................
  async deleteBusiness(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/businesses/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },









  //----------------------------------Releted Contacts-----------------------------------
  async findContactByAccountId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts/" + id + "/contacts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },


  //************************ Task ***********************************//
  async fetchTasks(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/" + pid + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      //console.log(result)
      return result;
    }
    return null;

  },


  async fetchMessages(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/messages/" + pid + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      //console.log(result)
      return result;
    }
    return null;

  },

  // async fetchUnreadMessages() {
  //   const token = localStorage.getItem("token");
  //   ///"+pid+"/*
  //   let response = await fetch(constants.API_BASE_URL + "/api/messages/unread", {
  //     method: 'GET',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': token
  //       // 'Content-Type': 'application/x-www-form-urlencoded',
  //     }
  //   });
  //   // console.log("Response Notification ==> ", response)
  //   const result = await response.json();
  //   // console.log("Result Notification ==> ", result);
  //   if (result.length > 0) {
  //     //console.log(result)
  //     return result;
  //   }
  //   return null;
  // },

  //************************ Update unread messsage status (unread to read) 13-07-2023 ***********************************//
  async updateStatusUnreadMessage(msgId) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/messages/readalertmsg/" + msgId,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: null,
      }
    );
    return await response.json();
  },

  //************************ Task ***********************************//
  async fetchBusinessByParent(pid) {
    const token = localStorage.getItem("token");
    ///"+pid+"/*
    let response = await fetch(constants.API_BASE_URL + "/api/businesses/" + pid + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {
      //console.log(result)
      return result;
    }
    return null;

  },

  async fetchTasksWithoutParent() {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/tasks/opentasks", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {

      return result;
    }
    return null;

  },

  async fetchAllMeetings(today) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/tasks/meetings/today", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    //console.log(response)
    const result = await response.json();
    //console.log(result);
    if (result.length > 0) {

      return result;
    }
    return null;

  },

  async createTask(task) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(task),

      });

    return await response.json();

  },

  async createMessage(message) {
    console.log('createMessage', JSON.stringify(message));

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/messages/",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(message),
      });
    return await response.json();

  },
  /**create usermessagealert */
  async createUserMsgAlert(msgList) {
    // console.log('createUserMsgAlert==>', msgList)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/messages/usermsg",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(msgList),

      });
    return await response.json();
  },

  async sendEmailTask(task) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/tasks/sendemail",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(task),

      });

    return await response.json();

  },

  async saveTask(task) {
    //console.log(task.id)
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + task.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(task),
      }
    );

    return await response.json();

  },

  async deleteTask(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/tasks/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },

  async deleteMessage(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/messages/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();

  },


  // ***********************************Home Page***************************************** /api/common/newleads
  //...................  Fetch All New Leads ................................
  async fetchNewLeads() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/newleads", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },
  async fetchallcontacts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/allcontacts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  async fetchTotalBusiness() {
    // console.log('business call:');
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/totalbusiness", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },


  async fetchallActiveusers() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/activeusers", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  async fetchLeadReports(reportname) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/byname/" + reportname, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log("Rsult", result);
    return result;
    // if (result.length > 0) {
    //   return result;
    // }
    // return null;
  },

  async getCurrentUserTrackingRec() {
    const token = localStorage.getItem("token");
    //console.log("getCurrentUserTrackingRec");
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/track/currentrecord",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    //console.log("current record from api  in fetch =>", result);
    if (result) {
      ////console.log('result in fetch => ', result)
      return result;
    }
    return null;
  },

  async saveStaffMemberEditProfile(userid, selectedFiles, staffMember) {
    const formData = new FormData();
    formData.append('file', selectedFiles);
    formData.append('staffRecord', staffMember);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/" + userid + "/profile", {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: token,
      },
      body: formData,
    }
    );
    return await response.json();
  },


  async createCheckInRecord(locationRecord) {
    const token = localStorage.getItem("token");
    //console.log("UserLocationRecord => ", locationRecord);

    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(locationRecord),
    });

    if (response.status === 401) {
      //authApi.logout();
    }
    const result = await response.json();
    //console.log("result in fetch =>", result);
    if (result) {
      ////console.log('result in fetch => ', result)
      return result;
    }
    return null;
  },
  async fetchUsertrackingsWithstaffId(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/usertrackings/staff/" + staffId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    //console.log('result', result)
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  async fetchSystemNotifications(staffId) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/notifications", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }

    });
    const result = await response.json();
    //console.log('result', result)
    if (result && result.length > 0) {
      return result;
    }
    return null;

  },


  async handlCheckOut(locationRecord) {
    const token = localStorage.getItem("token");
    //console.log("handlCheckOut => ", locationRecord);
    let response = await fetch(
      constants.API_BASE_URL + "/api/usertrackings/" + locationRecord.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(locationRecord),
      }
    );

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    //console.log("rsult in checkout => ", result);
    if (result) {
      return result;
    }
    return null;
  },


  async getLoginUserData() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/auth/getuser", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    //console.log('----->', JSON.stringify(result))

    return result;

  },



  // working
  async fetchCompanySetting(settingName) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/common/settings/" + settingName, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    return result;
  },





  async fetchFiles(parentId) {
    //console.log('--parentid--', parentId)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/files/" + parentId + "/all", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });

    const result = await response.json();
    //console.log('result', result);
    //console.log(response)
    if (result.length > 0) {
      return result;
    }
    return null;

  },




  async updateUser(user) {
    //console.log(user)
    const token = localStorage.getItem("token");
    let response = await fetch(

      constants.API_BASE_URL + "/api/auth/updatepassword",
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(user),
      }
    );

    return await response.json();

  },



  async downloadFiles(fileid) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + fileid + "/download",
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    // console.log('filebody in api ', fileBody)
    return fileBody;

  },

  async downloadBackupFile(filename) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/download/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    const fileBody = await response.blob();
    // console.log('filebody in api ', fileBody)
    return fileBody;

  },

  async deleteBackupFile(filename) {
    //console.log("save", fileid);
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/backup/delete/" + filename,
      {
        method: "GET",
        //mode: "cors",

        headers: {
          "Authorization": token
        }
      }
    );
    return await response.json();


  },

  //************************ Accounts ***********************************//

  //.................... Crate Account ...................................
  async createAccount(account) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/accounts",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(account),

      });
    return await response.json();

  },

  async saveAccount(account) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/accounts/" + account.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(account),
      }
    );
    return await response.json();

  },

  //.......... Fetch All Accounts ..........................................
  async fetchAccounts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Account By Id .............................
  async fetchAccountById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //............. Delete Account ............................
  async deleteAccount(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/accounts/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },


  //************************ Contacts ***********************************//

  //................... Create Contact ................................







  async createUser(user) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/auth/createuser",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(user),

      });
    //console.log('response',response)
    return await response.json();

  },




  async saveUser(user) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/auth/" + user.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(user),

      }
    );
    //console.log('response',response)

    return await response.json();

  },



  //.............. Fetch Contact By Id .............................
  async fetchContact(id) {
    // console.log(id);
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Contact By Recordtype Id .............................
  async fetchContactByRecordtype(rtype) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/t/" + rtype, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Contact By Recordtype Id .............................
  async fetchContactByPhone(ph) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/p/" + ph, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  // .......... Delete Contact .............................





  //************************ Products ***********************************//


  // .................. create product ...................................
  async createProduct(product) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(product),

      });
    return await response.json();
  },

  async saveLead(lead) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + lead.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(lead),
      }
    );
    return await response.json();
  },

  //.................. save Product ............................/
  async saveProduct(product) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/products/" + product.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(product),
      }
    );
    return await response.json();

  },

  //................... Fetch All Products ..............................//
  async fetchProducts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //.............. Fetch Product By Id .............................
  async fetchProduct(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/products/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //............. Delete Product .........................
  async deleteProduct(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/products/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },


  //********************* Price Book *************************************

  //.................... Crate Pricebook ...................................
  async createPricebook(pricebook) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(pricebook),

      });
    return await response.json();
  },

  async savePricebook(pricebook) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/pricebooks/" + pricebook.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(pricebook),
      }
    );
    return await response.json();

  },

  //.......... Fetch All Pricebooks........................................
  async fetchPricebooks() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;

  },

  //.............. Fetch Pricebook By Id .............................
  async fetchPricebook(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks/" + id + "/*", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },

  //.............. Fetch Pricebook By Owner Id .............................
  async fetchPricebookByOwnerId(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/pricebooks/" + id + "/pid", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();
    return result;
  },

  //............. Delete Pricebook ............................
  async deletePricebook(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/pricebooks/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },



  //************* Payment ****************************/

  //.................... Create Payment ...................................
  async createPayment(payments) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payments",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(payments),

      });
    return await response.json();
  },

  async savePayment(payment) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/payments/" + payment.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(payment),
      }
    );
    return await response.json();

  },
  //.............. Fetch Payment By business id .............................
  async fetchPayments(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payments/parent/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });
    const result = await response.json();
    return result;
  },



  //.............. Fetch Payment By  id .............................
  async fetchPaymentById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/payments/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token

      }

    });
    const result = await response.json();
    return result;
  },

  async deletePayment(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/payments/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },


  //****************** File  *******************

  async createFile(id, formData) {
    console.log('formData', formData)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/files/" + id, {//whatsNum
      method: "POST",
      mode: "cors",
      headers: {
        // "Content-Type": "application/json",
        "Authorization": token,
      },
      body: formData,
    }
    );

    return await response.json();
  },



  //************************ files ***********************************//

  async saveFiles(file) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + file.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(file),
      }
    );

    return await response.json();

  },



  async convertLeadToContact(leadId, convertStatus) {
    // console.log('convertStatus', convertStatus)

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/convert/" + leadId, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({ status: convertStatus }),

    });
    //console.log('===>',response)
    const result = await response.json();
    //console.log(result)
    if (result) {
      //console.log(result)
      return result;
    }
    return null;
  },


  async deleteFile(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/files/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },

  // --------------------------------- Report ----------------------------------------

  async fetchReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },


  async fetchReportsById(row) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/" + row.id,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });
    return await response.json();
  },

  //.............. Fetch Total Count of Accounts .............................
  async fetchCountOfAccounts() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/accounts/cnt", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Total Count of Doctors in Contact......................
  async fetchCountOfDoctors() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/total/dr", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  //.............. Fetch Total Count of Patients in Contact......................
  async fetchCountOfPatients() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/contacts/total/pt", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },

  // --------------------------------- Commission ----------------------------------------
  async fetchCommissions() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async createCommissions(commisson) {
    //console.log('commisson',commisson)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(commisson),
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async saveCommissions(commission) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions/" + commission[0].id, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(commission),
    });
    const result = await response.json();
    if (result) {
      return result;
    }
    return null;
  },

  async deleteCommissions(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/commissions/" + id, {
      method: "DELETE",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  //Report Builder 

  async fetchFilterQueryData(query) {
    // console.log(query)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders/filter/" + query, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    // console.log(result)
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchTableFields(tablename) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders/" + tablename, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchGroupbyQueryData(query) {
    // console.log('query', query)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders/groupbydata/" + query, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    // console.log(response)
    const result = await response.json();
    // console.log(result)

    return result;

  },
  async fetchTables() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchAllReports() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async fetchQueryData(query) {
    console.log('fetchQueryData ==> ', query)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reportbuilders/record/" + query, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const result = await response.json();
    console.log(result)
    console.log(result)
    if (result.length > 0) {
      return result;
    }
    return null;
  },
  async createReport(reportdata) {
    console.log('enter in inventory', reportdata)
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(reportdata),

      });
    return await response.json();
  },
  async fetchReportById(id) {

    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/reports/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }

    });
    const result = await response.json();
    console.log('result : ', result);
    return result;
  },








  //********************ABDUL PATHAN || START *******************************************************

  //create contact record
  async createContactRecord(contact) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/contacts",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(contact),

      });
    return await response.json();
  },
  // Update contact record
  async updateContactRecord(contact) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/whatsapp/contacts/" + contact.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(contact),
      }
    );
    return await response.json();
  },

  //Fetch All Contacts
  async getAllContactRecords(textName, cityName) {
    const token = localStorage.getItem("token");
    let response = await fetch(`${constants.API_BASE_URL}/api/whatsapp/contacts?textName=` + textName + '&cityName=' + cityName, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },
  // ****************contact lead user 
  // async fetchContactRecords(textName, cityName, date) {
  //   const token = localStorage.getItem("token");
  //   let endPointURL;
  //   if (date) {
  //     endPointURL = `${constants.API_BASE_URL}/api/whatsapp/chat/contact?textName=` + textName + '&cityName=' + cityName + '&date=' + date;
  //   } else {
  //     endPointURL = `${constants.API_BASE_URL}/api/whatsapp/chat/contact?textName=` + textName + '&cityName=' + cityName;
  //   }

  //   let response = await fetch(endPointURL, {
  //     method: 'GET',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': token
  //     }
  //   });

  //   if (response.status === 401) {
  //     authApi.logout();
  //   }
  //   const result = await response.json();
  //   return result;
  // },


  async getFilterData(textName, cityName, recordType) {
    const token = localStorage.getItem("token");
    const params = new URLSearchParams({ textName: textName || '', cityName: cityName || '', recordType: recordType || '' }).toString();
    let response = await fetch(`${constants.API_BASE_URL}/api/whatsapp/chat/filter?${params}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
  },

  async fetchUnreadMsgCount() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/chat/unread_count", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;

  },

  async markMessagesAsRead(whatsappNumber) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/chat/mark_as_read", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({ whatsapp_number: whatsappNumber })
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
  },


  // delete contact record
  async deleteContactRecord(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/whatsapp/contacts/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );

    return await response.json();
  },

  //************************* WHATSAPP SETTING || START *******************************************************************//
  async getWhatsAppSettingRecord() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp_setting", {///api/whatsapp_setting?name=" + name,
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;
  },

  async insertWhatsAppSettingRecords(reqbody) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp_setting", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },

  async updateWhatsAppSettingRecord(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp_setting/" + data.id, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      },
      body: JSON.stringify(data),
    }
    );
    return await response.json();
  },

  //************* WHATSAPP SETTING || END *****//

  /******Template || START ************************ */
  async getAllTemplates() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template/alltemplate", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    return await response.json();
  },

  async getApprovedTemplates() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template/approved/template", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    return await response.json();
  },

  async generateSessionId(file) {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("files", file);
    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template", {//http://localhost:3003/api/webhook_template/uploadsessionid
      method: 'POST',
      mode: 'cors',
      headers: {
        'Authorization': token,
      },
      body: formData,
    });

    return await response.json();
  },

  async uploadDocumentSessionId(file, sessionId) {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("files", file);
    formData.append("uploadSessionId", sessionId)
    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template/uploadsessionid", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Authorization': token,
      },
      body: formData,
    });
    return await response.json();
  },

  async createMarketingTemplate(reqbody) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template/template", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },

  async updateMarketingTemplate(id, reqbody) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template/template/" + id, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },

  async upsertAuthTemplate(reqbody) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template/temp/auth", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },
  // Delete Template
  async deleteTemplateRecord(id, name) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + '/api/webhook_template/template?hsm_id=' + id + '&name=' + name, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      }
    });
    const result = await response.json();
    return result;
  },

  /****************************Template || END ************************************************* */

  //************************* CREATE TEMPLATE IN DATABASE || START *********************************//
  async createMessageTemplateData(reqbody) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/message/template", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },

  // delete template database record
  async deleteTemplateDatabase(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/message/template/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      }
    }
    );
    return await response.json();
  },

  //************************* CREATE TEMPLATE IN DATABASE || END **********************************************//

  //************************* CREATE Campaign Records || START **********************************************//
  async getCampaignData() {
    const token = localStorage.getItem("token");
    let response = await fetch(`${constants.API_BASE_URL}/api/whatsapp/campaign`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();

    return result;
  },

  async insertCampaignRecords(reqbody) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/campaign", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },

  async updateCampaignRecord(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/campaign/" + data.id, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      },
      body: JSON.stringify(data),
    }
    );
    return await response.json();
  },

  async downloadCampaignFile(filename) {
    const token = localStorage.getItem("token");
    try {
      let response = await fetch(
        constants.API_BASE_URL + "/api/whatsapp/campaign/download/" + filename, {
        method: "GET",
        headers: {
          "Authorization": token
        }
      }
      );

      if (!response.ok) {
        if (response.status === 404) {
          return null;
        } else if (response.status === 401) {
          throw new Error("Unauthorized access. Please login.");
        } else {
          throw new Error("Failed to download file. Status: " + response.status);
        }
      }

      const fileBody = await response.blob();
      return fileBody;

    } catch (error) {
      console.error('Error downloading file:', error);
      throw error; // Re-throw error for further handling if needed
    }
  },

  async getMsgHistoryDownload(id) {// 
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/message/history/download/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }

    const result = await response.json();
    return result;

  },

  async deleteCampaignRecord(id) {//not use
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/campaign/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      }
    }
    );
    return await response.json();
  },

  async createCampaignFile(id, formData) {
    console.log('formData', formData)
    const token = localStorage.getItem("token");
    // let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/files/campaign/" + id, {
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/campaign/file/" + id, {
      method: "POST",
      mode: "cors",
      headers: {
        "Authorization": token,
      },
      body: formData,
    }
    );
    return await response.json();
  },

  //************************* CREATE Campaign Records || END **********************************************//



  //************************* SEND WHATSAPP MESSAGE || START **********************************************//
  async sendWhatsAppTemplateMessage(reqbody) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template/message", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },

  async sendSingleWhatsAppTextMessage(reqbody) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template/single/message", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },
  // upload Documents get id
  async uploadDocumentWithApi(file) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/webhook_template/documentId", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Authorization': token,
      },
      body: file,
    });
    return await response.json();
  },

  //************************* SEND WHATSAPP MESSAGE || END **********************************************//

  //************************* CREATE MESSAGE HISTORY || START **********************************************//
  async getMsgHistoryRecords(number) {// 
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/message/history/" + number, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;

  },

  async insertMsgHistoryRecords(reqbody) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/message/history", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },

  async getGroupHistoryRecords(id) {// 
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/group/message/history/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;

  },

  //************************* CREATE MESSAGE HISTORY || END **********************************************//

  // *******response Message || START ***********************************
  async getResponseMessageData() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/response_message", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    console.log('result', result)
    return result;
  },

  async inserResponseMessageRecord(reqbody) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/response_message", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(reqbody),
    });
    return await response.json();
  },

  async updateResponseMessageRecord(data) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/response_message/" + data.id, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      },
      body: JSON.stringify(data),
    }
    );
    return await response.json();
  },

  async deleteResponseMessageRecord(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/response_message/" + id, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      }
    });
    return await response.json();
  },
  // *******response Message || END ***********************************



  //************START || GROUP  **************************************************/
  async fetchGroupsById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/groups/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    return await response.json();
  },


  async fetchGroups(status) {
    const token = localStorage.getItem("token");
    const params = new URLSearchParams({ status: status || '' }).toString();

    let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/groups?" + params, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    return result;

  },
  async createGroup(formData) {
    const token = localStorage.getItem("token");

    try {
      let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/groups", {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': token,
        },
        body: formData,
      });

      const jsonResponse = await response.json();

      if (!response.ok) {
        throw new Error(`Error: ${jsonResponse.message || "Unknown error"}`);
      }

      return jsonResponse;
    } catch (error) {
      console.log("Failed to create group:", error);
      throw error;
    }
  },
  async addMoreMembers(groupId, formData) {
    const token = localStorage.getItem("token");

    try {
      let response = await fetch(constants.API_BASE_URL + "/api/whatsapp/groups/add_members/" + groupId, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': token,
        },
        body: formData,
      });

      const jsonResponse = await response.json();

      if (!response.ok) {
        throw new Error(`Error: ${jsonResponse.message || "Unknown error"}`);
      }

      return jsonResponse;
    } catch (error) {
      console.log("Failed to create group:", error);
      throw error;
    }
  },

  async deleteGroupMember(member_id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/whatsapp/groups/member/" + member_id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },

  async changeGroupStatus(group_id, status) {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${constants.API_BASE_URL}/api/whatsapp/groups/${group_id}/status`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify({ status })
      }
    );

    return await response.json();
  },


  //************END || GROUP  **************************************************/

  // **************Lead || START ************************************
  async fetchLead() {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads", {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (response.status === 401) {
      authApi.logout();
    }
    const result = await response.json();
    if (result.length > 0) {
      return result;
    }
    return null;
  },

  async fetchLeadById(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(constants.API_BASE_URL + "/api/leads/" + id, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }

    });
    const result = await response.json();

    return result;
  },
  async createLead(lead) {
    const token = localStorage.getItem("token");

    let response = await fetch(constants.API_BASE_URL + "/api/leads",
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(lead),

      });
    return await response.json();
  },
  async updateLead(lead) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + lead.id,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify(lead),
      }
    );
    return await response.json();
  },

  async deleteLead(id) {
    const token = localStorage.getItem("token");
    let response = await fetch(
      constants.API_BASE_URL + "/api/leads/" + id,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        }
      }
    );
    return await response.json();
  },

  // **************Lead || END ************************************



}
export default WhatsAppAPI