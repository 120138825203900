import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

const PieChart = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Properties sales monthly',
      },
    },
  };
  const data = {
    labels: ['Pending', 'In porgress', 'Completed', 'Aborted'],
    datasets: [
      {
        label: '# of Votes',
        data: [3, 2, 3, 2,],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 211, 255, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 211, 255, 0.2)'],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Pie data={data} options={options} />
  )
}

export default PieChart